.plan_container{
    position: relative;
}
    
.program_headers{
    gap: 16px;
}
    
.program_headers h3{
    text-transform: uppercase;
    font-size: 50px;
    color: white;
    font-style: italic;
    text-align: center;
}
h3.text-trock{
    color: transparent;
}
.plan{
    max-width: 1100px;
    margin: auto;
    margin-top: 100px;
    padding-bottom: 100px;
}
.features_main{
    flex-direction: column;
    background-color: var(--caloryCard);
    padding: 2rem;
    margin: 20px;
}
.features_main svg{
    fill: white;
    width: 2rem;
    height: 2rem;
    margin-bottom: 20px;
}
.features_main>span:nth-of-type(1){
    margin-bottom: 20px;
    font-weight: bold;
}
.features_main>span:nth-of-type(2){
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 28px;
}
.featurs{
    gap: 20px;
    margin-bottom: 10px;
}
.features_main:nth-child(2){
    transform: scale(1.1);
    background: var(--planCard);
}
.features_main .btn{
    display: block;
    width: inherit;
}
.plan_btn{
    color: black;
    position: relative;
}
.plan_btn::after{
    content: '';
    position: absolute;
    background: url('../../assets/arrow-right.svg') no-repeat center ;
    background-size: cover;
    height: 20px;
    width: 20px;
    right: 65px;
    opacity: 0;
    transition: .5s all ease;
}
.plan_btn:hover::after{
    opacity: 1;
    right: 60px;
}
.plan_blur1{
    top: 4rem;
    left: 0;
    width: 32rem;
    height: 23rem;
}
.plan_blur2{
    top: 4rem;
    right: 0;
    width: 32rem;
    height: 23rem;
}
@media screen and (max-width:1280px) {
    
}
@media screen and (max-width:1024px) {
    .program_headers{
        flex-wrap: wrap;
        column-gap: 30px;
        justify-content: center;
        text-align: center;
    }
    .program_headers h3{
        font-size: 34px;
    }
    .plan{
        margin-top: 50px;
        padding-bottom: 50px;
    }
    .features_main{
        padding: 16px;
        margin: 16px;
    }
    .plan_btn::after{
        right: 50px;
    }
    .plan_btn:hover::after{
        right: 45px;
    }
    .featurs{
        gap: 12px;
        font-size: 14px;
    }
    .featurs img{
        width: 22px;
    }
}

@media screen and (max-width:992px) {
    .plan_btn::after{
        right: 30px;
    }
    .plan_btn:hover::after{
        right: 25px;
    }
    .features_main > span:nth-of-type(2){
        font-size: 20px;
    }
}
@media screen and (max-width:767px) {
    .plan{
        flex-wrap: wrap;
    }
    .features_main{
        width: 100%;
        margin: auto;
        margin-bottom: 30px;
    }
    .features_main:last-child{
        margin-bottom: 0;
    }
    .features_main:nth-child(2){
        transform: scale(1);
    }
    .plan_btn::after{
        right: 40%;
    }
    .plan_btn:hover::after{
        right: 39%;
    }
    .plan_btn{
        margin: 0;
    }
}
@media screen and (max-width:540px) {
    .plan_btn::after{
        right: 32%;
    }
    .plan_btn:hover::after{
        right: 30%;
    }
}
