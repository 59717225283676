.join_section{
    align-items: end;
    margin-bottom: 100px;
}
.join_left{
    flex: 1 1;
}
.join_right{
    flex: 1 1;
}
.join_left h3{
    color: white;
    text-transform: uppercase;
    position: relative;
}
.join_left h3:first-child::after{
    content: '';
    position: absolute;
    left: 0;
    top: -10px;
    width: 210px;
    border-radius: 30%;
    height: 5px;
    background: var(--orange);
}
.join_right form{
    position: relative;
    display: flex;
}
.join_right form input[type="email"] {
    width: 80%;
    padding: 30px;
    margin-left: auto;
    font-size: 20px ;
    margin-bottom: 10px;
    background: var(--gray);
    border: none;
    color: white;
}
.join_right form input::placeholder{
    color: white;
    opacity: 1;
}
.join_right form input:active{
    border:1px solid red ;
}

.join_right form button {
    font-size: 20px ;
    margin-top: auto;
}
.form_btn{
    font-weight: bold;
    border:  1.5px solid transparent;
    background: var(--orange);
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
.form_btn:hover{
    background: transparent;
    border: 1.5px solid var(--orange);
}
@media screen and (max-width:1024px) {
    .join_right form input[type="email"]{
        width: 100%;
    }
}
@media screen and (max-width:992px) {
   
    .join_left, .join_right{
        flex: none;
        width: 100%;
        margin-bottom: 30px;
    }
    .join_section{
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
}
@media screen and (max-width:540px) {
    .join_right form button{
        font-size: 14px;
        width: 4rem;
        right: -8px;
        top: 0;
    }
    .join_right form input[type="email"]{
        font-size: 14px;
        padding: 0 10px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}