
.programs_header{
    column-gap: 5rem;
    justify-content: center;
    font-size: 3rem;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    margin: 50px 0;
    text-align: center;
    flex-wrap: wrap;
}
.program_cetogeri{
    gap: 2rem;
    margin-bottom: 50px;
    margin-top: 2rem;
    width: 100%;
    padding: 0 50px;
    flex-wrap: wrap;
}
.cetegori{
    background: var(--gray);
    flex-direction: column;
    padding: 2rem;
    flex: 1;
    transition: .5s all ease;
}
.cetegori>:first-child{
    fill: white;
    width: 2rem;
    height: 2rem;
    margin-bottom: 1rem;
}
.cetegori >:nth-child(2){
    margin-bottom: 20px;
    font-size: 20px;
}
.cetegori >:nth-child(3){
    margin-bottom: 20px;
}
.cetegori >:last-child img{
    margin-left: 20px;
    width: 20px;
    transition: .5s all ease;
}
.cetegori:hover{
    background: var(--planCard);
}
.cetegori:hover.cetegori >:last-child img{
    margin-left: 25px;
}
@media screen and (max-width:1024px) {
    .programs_header{
        font-size: 34px;
        column-gap: 2rem;
        row-gap: 1rem;
    }
    .program_cetogeri{
        padding: 0 30px;
    }
    .program_cetogeri{
        gap: 1.5rem;
    }
    .cetegori{
        width: 48%;
        flex: none;
    }
}
@media screen and (max-width:767px) {
    .program_cetogeri{
        gap: 1rem;
    }
    .program_cetogeri{
        padding: 0 20px;
    }
}
@media screen and (max-width:540px) {
    .cetegori{
        width: 100%;
        padding: 1.5rem;
    }
}
@media screen and (max-width:420px) {
    .programs_header{
        row-gap: 1rem;
    }
}