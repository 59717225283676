.reasons {
    gap: 40px;
    margin-bottom: 50px;
}

.left-s {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.left-s .left_s_img {
    width: 39%;
}
.left-s .right_s_img {
    width: fit-content;
    width: 60%;
    display: flex;
    flex-wrap: wrap;
}
.right_s_img>:nth-of-type(1){
    margin-bottom: 16px;
    width: 100%;
}

.img_bottum{
    gap: 16px;
    width: 100%;
}
.left-s .left_s_img>img, .img_bottum>img,  .right_s_img>:nth-of-type(1)>img{
    width: 100%;
    height: 100%;
}



.right-s {
    flex: 1 1;
    text-transform: uppercase;
}

.right-s .heading-sm-text {
    font-weight: bold;
    color: var(--orange);
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.right-s h3 {
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.right-s ul li {
    color: white;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    font-size: 18px;
    font-weight: bold;
}

.right-s ul li img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.right-s>:nth-child(4) {
    color: var(--gray);
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
}
.logos{
    gap: 14px;
}
@media screen and (max-width:1024px) {

    .reasons{
        gap: 30px;
        flex-direction: column;
    }
    .left-s, .right-s{
        flex: none;
        width: 100%;
    }
    .right-s .heading-sm-text{
        font-size: 18px;
        margin-bottom: 16px;
    }
    .right-s ul li{
        font-size: 16px;
    }

}
@media screen and (max-width:767px) {
    .img_bottum>img{
        width: 46%;
    }
}