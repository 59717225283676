.testimonials{
    margin-bottom: 100px;
    flex-wrap: wrap;
}
.left_testi{
    flex: 1;
}
.right_testi{
    flex: 1;
    position: relative;
}
.left_testi .sm_ttl{
    font-weight: bold;
    color: var(--orange);
    font-size: 20px;
    margin-bottom: 20px;
}
.left_testi h3{
    color: white;
    margin-bottom: 20px;
}
.left_testi h3.text-trock{
    color: transparent;
}
.left_testi .review{
    margin-bottom: 30px;
    line-height: 30px;
}
.right_img{
    width: 18rem;
    height: 22.5rem;
    margin-left: auto;
    position: relative;
}
.img_bg{
    position: absolute;
    left: 20px;
    top: 20px;
    width: 100%;
    z-index: -1;
    height: 100%;
    background: var(--planCard);
}
.img_border{
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 100%;
    height: 100%;
    z-index: -2;
    border: 1px solid var(--orange);
}
.arrows{
    position: absolute;
    bottom: 0;
    gap: 20px;
    left: 30%;
}
.arrows img{
    width: 33px;
    height:  30px;
    cursor: pointer;
}
@media screen and (max-width:1280px) {
    .arrows{
        left: 0;
    }
}
@media screen and (max-width:1024px) {
    .right_img{
        margin-right: 2rem;
    }
}
@media screen and (max-width:992px) {
    .arrows{
        left: -20%;
    }
}
@media screen and (max-width:767px) {
    .left_testi, .right_testi{
        flex: none;
        width: 100%;
    }
    .left_testi{
        margin-bottom: 50px;
    }
    .arrows{
        left: 0%;
    }
}
@media screen and (max-width:540px) {
    .right_img{
        width: 14rem;
        height: 17.5rem;
    }
}
@media screen and (max-width:420px) {
    .arrows{
        bottom: -50px;
        width: fit-content;
        margin: auto;
    }
    .arrows img {
        width: 22px;
        height: 19px;
    }
}