.hero {
    position: relative;
}

.hero-left {
    flex: 3;
    margin-top: 100px;
}

.hero-right .container {
    width: 100%;
}

.hero-left-content {
    margin-top: 4rem;
    position: relative;
    background: #363d42;
    border-radius: 4rem;
    padding: 20px 13px;
    justify-content: flex-start;
    width: max-content;
    z-index: 1;
    margin-bottom: 1.5rem;
}

.hero-left-content>span {
    text-transform: uppercase;
    color: white;
}

.hero-left-content>div {
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    border-radius: 4rem;
    z-index: -1;
    animation: orangeBg ease-in-out infinite 4s;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
}

/* @keyframes orangeBg{
    0%   { left:0%; }
    100% { left:73%; }
} */

.banner-ttl {
    color: white;
    text-transform: uppercase;
}

.banner-ttl h2 {
    margin-bottom: 10px;
}

.banner-sub-ttl {
    color: white;
    width: 80%;
    margin-top: 20px;
    margin-bottom: 40px;
}

.figurs>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
    margin-bottom: 20px;
}

.figurs>div:first-child {
    margin-left: 0;
}

.figurs>div>span:nth-of-type(1) {
    font-size: 2rem;
    margin-bottom: 10px;
    font-weight: 600
}

.figurs>div>span:nth-of-type(2) {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500;
    color: var(--gray);
}

.banner-button a:first-child {
    background-color: var(--orange);
    margin-left: 0;
    border: 1px solid transparent;
}

.banner-button a:first-child:hover {
    background: transparent;
    border: 1px solid var(--orange);
}

.banner-button a:last-child {
    border: 1px solid var(--orange);
    background: transparent;
}

.banner-button a:last-child:hover {
    background: var(--orange);
}

.hero-right {
    flex: 1;
    background-color: var(--orange);
    position: relative;
    margin-right: -50px;
}

.heart-rate {
    flex-direction: column;
    background: var(--appColor);
    width: fit-content;
    position: absolute;
    padding: 1rem;
    border-radius: 10px;
    left: 6rem;
    top: 7rem;
}

.heart-rate span:nth-of-type(1) {
    color: var(--gray);
    padding: 10px 0;
}

.heart-rate span:nth-of-type(2) {
    font-size: 2rem;
}

.hero_img {
    width: 23rem;
    position: absolute;
    right: 8rem;
    top: 12rem;
}

.hero_image_back {
    position: absolute;
    top: 4rem;
    right: 18rem;
    z-index: -1;
    width: 16rem;
}

.celoris {
    width: fit-content;
    background: var(--caloryCard);
    padding: 1rem;
    position: absolute;
    bottom: 6rem;
    left: -22rem;
    border-radius: 10px;
    position: absolute;
}

.celoris img {
    width: 3rem;
}

.celoris div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    gap: 10px;
}

.celoris div span:nth-of-type(1) {
    color: var(--gray);
    font-size: 20px;
}

.celoris div span:nth-of-type(2) {
    color: white;
    font-size: 2rem;
}

.hero_blur {
    width: 40rem;
    height: 30rem;
    left: 0;
}

@media screen and (max-width:1280px) {
    .hero-left {
        flex: 2.5;
    }

    .hero-right {
        flex: 1;
    }

    .hero_img {
        width: 18rem;

    }
}

@media screen and (max-width:1024px) {
    .banner-ttl h2 {
        margin-bottom: 0;
    }

    .hero {
        flex-direction: column;
    }

    .hero-left {
        width: 100%;
        flex: none;
    }

    .hero-right {
        flex: none;
        width: 100%;
        position: relative;
        background: none;
        min-height: 500px;
    }

    .hero-right::after {
        position: absolute;
        content: '';
        width: 50%;
        height: 100%;
        background: var(--orange);
        right: -30px;
        top: 0;
        z-index: -1;
    }

    .hero_img {
        top: 5rem;
        right: 8rem;
    }

    .heart-rate {
        left: inherit !important;
        right: 10px;
        top: 50px;
    }

    .hero_image_back {
        right: 19rem !important;
        left: inherit !important;
    }

    .celoris {
        left: 20rem !important;
        bottom: 3rem;
    }

}

@media screen and (max-width:767px) {
    .celoris {
        left: 20% !important;
    }

    .hero_image_back {
        right: 17rem !important;
    }

    .hero-left {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .figurs>div>span:nth-of-type(1),
    .heart-rate span:nth-of-type(2),
    .celoris div span:nth-of-type(2) {
        font-size: 1.50rem;
    }

    .figurs>div>span:nth-of-type(2) {
        font-size: 0.9rem;
    }

    .hero-left-content>span {
        font-size: 13.7px;
    }
    .figurs>div{
        margin: 0 15px 20px;
    }
}

@media screen and (max-width:540px) {
    .celoris {
        left: 0% !important;
    }

    .hero_image_back {
        right: 11rem !important;
        width: 14rem;
        top: 5rem;
    }

    .hero_img {
        top: 8rem;
        right: 5rem;
        width: 15rem;

    }
}