.header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: .5s all ease;
}
.logo{
    max-width: 240px;
}

nav ul li{
    margin: 0 10px;
}
nav ul li a{
    transition:  .2s all ease;
}
nav ul li a:hover{
    color: black;
}

.join_btn{
    color: black;
    font-weight: 500;
    margin-bottom: 0;
    border: 1px solid transparent;
    margin-left: auto;
}
.join_btn:hover{
    border: 1px solid white;
    background: var(--orange);
    color: white;
}
/* .active{
    background: var(--orange);
    padding-top: 15px;
    padding-bottom: 15px;
} */
.header.fixed {
    background: var(--orange);
    padding: 20px 0;
} 
.header .header-right {
    display: flex;
    flex-flow: row wrap;
    -webkit-align-items: center;
    align-items: center;
    margin-left: auto;
    color:white;
}
.menu-btn {
    width: 30px;
    height: 30px;
    position: relative;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    cursor: pointer;
    z-index: 8;
    display: none;
    margin: 0;
}

.menu-line {
    display: block;
    top: 50%;
    margin-top: -2px;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.menu-line,
.menu-line::before,
.menu-line::after {
    width: 30px;
    height: 3px;
    background-color: #ffffff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    position: absolute;
    left: 0;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.menu-line::before,
.menu-line::after {
    content: "";
    display: block;
}

.menu-line::before {
    top: -10px;
    transition: top 0.1s 0.34s ease-in,
        opacity 0.1s ease-in;
}

.menu-line::after {
    bottom: -10px;
    transition: bottom 0.1s 0.34s ease-in, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.menu-btn.active .menu-line {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
    transition-delay: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.menu-btn.active .menu-line::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out;
}

.menu-btn.active .menu-line::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, -webkit-transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}


@media screen and (max-width: 1024px) {
    .container{
        padding-left: 30px;
        padding-right: 30px;
    }
    .menu-btn {
        display: block;
        order: 3;
    }
    .header .header-right {
        display: none;
        position: fixed;
        top: 80px;
        right: 50px;
        width: 100%;
        max-width: 400px;
        background-color: var(--orange);
        padding: 20px;
    }
    .join_btn{
        margin-right: 30px;
    }
    .header .header-right nav{
        justify-content: center;
    }
    .header .header-right ul{
        flex-direction: column;
        width: 100%;
        text-align: center;
    }
    .header .header-right ul li{
        border-bottom:  1px solid white;
        width: 100%;
        margin: 0;
        padding: 15px 0;
    }
}
@media screen and (max-width: 767px) {
    .container{
        padding-left: 20px;
        padding-right: 20px;
    }
    .join_btn{
        display: none;
    }
    .logo{
        max-width: 150px;
    }
    .header{
        background: var(--orange);
        padding: 10px 0;
    }
    .header.fixed{
        padding: 10px 0;
    }
    .header .header-right {
        height: calc(100vh - 70px);
        max-width: inherit;
        right: 0;
        top: 62px;
    }
    .menu-line::before{
        top: -8px;
    }
    .menu-line::after{
        bottom: -8px;
    }
}