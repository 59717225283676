.footer_container{
    position: relative;
}
.footer{
    border-top: 1px solid white;
    padding-top: 100px;
    padding-bottom: 100px;
}
.footer_links{
    justify-content: center;
    gap: 4rem;
    margin-bottom: 30px;
}
.footer_links a img{
    width: 2rem;
    height: 2rem;
}
.f_logo{
    width: fit-content;
    margin: auto;
}
.f_blur-1{
    right: 15%;
    bottom: 0;
    width: 26rem;
    height: 15rem;
    filter: blur(100px);
}
.f_blur-2   {
    left: 15%;
    bottom: 0;
    width: 26rem;
    height: 15rem;
    filter: blur(100px);
}
@media screen and (max-width:1024px) {
    .footer{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .footer_links a img{
        width: 1.5rem;
        height: 1.5rem;
    }
    .f_logo{
        max-width: 180px;
    }
}